import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductIcon from "../components/productIcon"

function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
      darkColor : lightColor;
  }

const IndexPage = () => {

    const productData = require('../../data/products.json');
    const products = productData;

    const rawCategories = require('../../data/categories.json');

    const [ categories, setCategories ] = React.useState(
        Object.values(rawCategories).map(i => { return { ...i, checked: true }})
    );

    return <Layout>
        <section class="mt-16" id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div class="mx-auto h-full py-16 max-w-lg text-center lg:mx-0 lg:text-left flex align-top flex-col">
                    <h1 class="text-3xl font-bold sm:text-4xl">Products</h1>
                    <p class="mt-4 text-gray-600">
                        We have a number of products and services that are built into the Irishman Cloud. To properly understand all of the functionality that each product has, you can read about them below.
                    </p>
                </div>
                <div class="mx-auto w-full my-6 lg:mx-0 flex align-top flex-wrap">
                    {
                        categories.map((i, n) => {

                            return <button key={n} onClick={() => { setCategories([ ...categories.slice(0, n), { ...categories[n], checked: !i.checked }, ...categories.slice(n + 1), ]); }} className="m-1 w-fit border inline-flex gap-2 p-2 rounded justify-center items-center">
                                <div className="h-8 w-8 rounded flex justify-center items-center" style={{ backgroundColor: i.color }}> 
                                    { 
                                        i.checked &&
                                        <svg
                                            data-checked-icon
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill={pickTextColorBasedOnBgColorSimple(i.color, "#ffffff", "#000000")}
                                            >
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    }
                                </div>
                                <span className="text-slate-700 whitespace-nowrap">{ i.name }</span>
                            </button>

                        })
                    }
                </div>
                <div class="grid grid-cols-2 gap-4 sm:grid-cols-4 lg:grid-cols-6">
                    {
                        products.map((item) => { if (categories.filter(i => i.type == item.type && i.checked).length > 0) return ProductIcon({ ...item, href: `/products/${item.slug}` }) })
                    }
                </div>
            </div>
        </section>
        <section class="h-50 w-full p-16"></section>
    </Layout>
}

export const Head = () => <Seo title="Products" />

export default IndexPage
